<template>
  <!-- <tab-content title="Location"> -->
  <b-row>
    <b-col cols="12" class="mb-2">
      <div class="map-outer">
        <l-map
          :zoom="zoom"
          :center="latLng ? latLng : [0, 0]"
          ref="map"
          @update:center="updatelocation"
          @update:bounds="boundsUpdated"
        >
          <l-tile-layer :url="url" />

          <l-draw-toolbar position="topright"></l-draw-toolbar>
          <div v-if="polygon.show">
            <l-polygon
              :lat-lngs="polygon.latlngs"
              :color="polygon.color"
            ></l-polygon>
          </div>
          <div v-if="rectangle.show">
            <l-rectangle
              :bounds="rectangle.latlngs"
              :l-style="{ color: 'red', weight: 3 }"
            ></l-rectangle>
          </div>
          <div v-if="circle.show">
            <l-circle
              :latLng="circle.center"
              :radius="circle.radius"
              :color="circle.color"
            />
          </div>
        </l-map>
      </div>
    </b-col>
  </b-row>
  <!-- </tab-content> -->
</template>

<script>
import {
  LMap,
  LTileLayer,
  LMarker,
  LPolygon,
  LRectangle,
  LCircle
} from "vue2-leaflet";
import LDrawToolbar from "vue2-leaflet-draw-toolbar";
import "leaflet/dist/leaflet.css";
import { latLng } from "leaflet";
import "leaflet/dist/leaflet.css";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import "leaflet-geosearch/assets/css/leaflet.css";
import { GeoSearchControl } from "leaflet-geosearch";
import { Icon } from "leaflet";
import { BRow, BCol } from "bootstrap-vue";
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
export default {
  components: {
    LMap,
    LTileLayer,
    LDrawToolbar,
    LMarker,
    BRow,
    BCol,
    LPolygon,
    LRectangle,
    LCircle
  },
  data() {
    return {
      latLng: latLng(0, 0),
      zoom: 10,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      center: [51.505, -0.159],
      bounds: null,
      polygon: {
        latlngs: [],
        color: "green",
        show: false
      },
      circle: {
        center: [],
        radius: 0,
        color: "red",
        show: false
      },
      rectangle: {
        latlngs: [],
        color: "green",
        show: false
      }
    };
  },
  props: {
    data: {
      type: Object,
      default: () => []
    },
    setMapCoordinates: {
      type: Function
    },
    isValidCoordinates: {
      type: Function
    },
    ismapLoad: {
      type: Boolean
    },
    forceLoad: {
      type: Boolean
    }
  },
  watch: {
    ismapLoad(newValue, oldValue) {
      if (newValue !== oldValue) {
        setTimeout(() => {
          this.$refs.map.mapObject.invalidateSize();
          this.isValidCoordinates();
        }, 100);
      }
    },
    forceLoad() {
      const map = this.$refs.map.mapObject;
      map.eachLayer((layer) => {
        if (!!layer.toGeoJSON) {
          map.removeLayer(layer);
        }
      });
      this.mapLoad();
    }
  },
  mounted() {
    this.mapLoad();
  },
  methods: {
    mapLoad() {
      setTimeout(() => {
        this.$refs.map.mapObject.invalidateSize();
      }, 100);
      const map = this.$refs.map.mapObject;
      const provider = new OpenStreetMapProvider();
      const searchControl = new GeoSearchControl({
        provider,
        autoComplete: true,
        autoCompleteDelay: 250,
        showMarker: true
      });
      map.addControl(searchControl);
      map.on("geosearch/showlocation", this.geosearch);
      map.on("draw:created", this.drawer);
      this.zoom = 10;
      if (this.data && this.data.fenceType == "circle") {
        this.zoom = 15;
        this.circle["center"] = latLng([
          this.data["value"]["lat"],
          this.data["value"]["lng"]
        ]);
        this["center"] = this.circle["center"];
        this.circle["radius"] = this.data["radius"];
        this.circle["show"] = true;
        this.polygon["show"] = false;
        this.rectangle["show"] = false;
        this.latLng = this.circle["center"];

        this.$refs.map.setCenter([
          this.data["value"]["lat"],
          this.data["value"]["lng"]
        ]);
        // map.fitBounds([this.circle["center"]]);
      } else if (
        this.data &&
        this.data.fenceType == "polygon" &&
        this.data["value"] /*||
        Object.keys(this.data).indexOf("fenceType") == -1*/
      ) {
        this.zoom = 15;
        this.polygon["latlngs"] = this.data["value"];
        this.latLng = this.polygon.latlngs;
        this.circle["show"] = false;
        this.polygon["show"] = true;
        this.rectangle["show"] = false;
        //  this.$refs.map.fitBounds([[18.514901,73.926295]]);
        if (this.data["value"] && this.data["value"].length) {
          this.$refs.map.fitBounds([
            [this.data["value"][0][0], this.data["value"][0][1]]
          ]);
        }
      } else if (this.data && this.data.fenceType == "rectangle") {
        this.zoom = 15;
        this.rectangle["latlngs"] = this.data["value"];
        //  this.latLng = this.rectangle.latlngs;
        this.circle["show"] = false;
        this.polygon["show"] = false;
        this.rectangle["show"] = true;
        if (this.data["value"] && this.data["value"].length) {
          map.fitBounds([[this.data["value"][0][0], this.data["value"][0][1]]]);
        }
      } else {
        if (navigator.geolocation) {
          this.zoom = 15;
          navigator.geolocation.getCurrentPosition(
            (position) => {
              this.latLng = [
                position.coords.latitude,
                position.coords.longitude
              ];
            },
            (error) => {
              console.log(error);
            }
          );
        }
      }
    },
    zoomUpdated(zoom) {
      this.zoom = zoom;
    },
    centerUpdated(center) {
      this.center = center;
    },
    boundsUpdated(bounds) {
      this.bounds = bounds;
    },
    geosearch(e) {
      this.latLng = [e.marker._latlng.lat, e.marker._latlng.lng];
    },
    drawer(e) {
      const map = this.$refs.map.mapObject;
      map.eachLayer((layer) => {
        if (!!layer.toGeoJSON) {
          map.removeLayer(layer);
        }
      });
      const me = this;
      setTimeout(() => {
        if (e.layerType == "circle") {
          me.circle["center"] = latLng(e.layer._latlng);
          me["center"] = me.circle["center"];
          me.circle["radius"] = e.layer._mRadius;
          me.circle["show"] = true;
          me.polygon["show"] = false;
          me.rectangle["show"] = false;
          //  me.latLng = latLng(e.layer._latlng);
          // if (me.zoom < 15) {
          //   me.zoom = 15;
          // }

          //  map.fitBounds([e.layer._latlng]);
          me.setMapCoordinates({ ...me.circle, fenceType: e.layerType });
        } else if (e.layerType == "rectangle") {
          //   me.zoom = 10;
          me.rectangle.latlngs = e.layer._latlngs;
          me.circle.center = [];
          me.polygon.latlngs = [];
          me.circle["show"] = false;
          me.polygon["show"] = false;
          me.rectangle["show"] = true;
          //   me.latLng = me.rectangle["latlngs"];
          me["center"] = [
            e.layer._bounds.getCenter().lat,
            e.layer._bounds.getCenter().lng
          ];
          me.latLng = e.layer._bounds.getCenter();
          map.fitBounds(e.layer._bounds);
          me.setMapCoordinates({ ...me.rectangle, fenceType: e.layerType });
        } else if (e.layerType == "polygon") {
          //  me.zoom = 10;
          me.polygon.latlngs = [];
          me.polygon.latlngs = e.layer._latlngs;
          me.circle.center = [];
          me.circle.circleLoad = false;
          me.circle["show"] = false;
          me.polygon["show"] = true;
          me.rectangle["show"] = false;
          me.latLng = e.layer._bounds.getCenter();
          me.center = e.layer._bounds.getCenter();
          // console.log(me.polygon.latlngs);
          map.fitBounds(me.polygon.latlngs);
          me.setMapCoordinates({ ...me.polygon, fenceType: e.layerType });
        }
        me.isValidCoordinates();
      }, 200);
    },
    updatelocation(latLng) {
      this.latLng = [latLng.lat, latLng.lng];
    }
  }
};
</script>
<style lang="scss">
.vue2leaflet-map {
  &.leaflet-container {
    height: 600px;
    height: calc(100vh - 107px);
  }
}
.map-outer {
  width: 100%;
}
.leaflet-popup-content-wrapper {
  padding: 0px !important;
}
.leaflet-popup {
  bottom: 17px !important;
}
@media screen and (max-width: 991px) {
  .vue2leaflet-map {
    &.leaflet-container {
      height: calc(100vh - 300px);
    }
  }
}
</style>
